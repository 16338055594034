<template>
    <div id="login-container" style="height: 100vh;">
      <div class="row d-flex justify-content-center align-items-center m-0">
        <div class="text-center col-12 py-5">
          <img class="main-logo mouse-pointer mw-100" src="../../assets/images/logo.png" :alt="description" height="65"/>
          <h1 class="title font-weight-bold">{{ description }}</h1>
        </div>
      </div>

      <div v-if="!forgotPassword" class="row d-flex justify-content-center align-items-center m-0">
        <div class="col-12 col-md-6 col-lg-5 py-5">
          <div class="card text-center bg-light">
            <div class="card-body p-5">
              <b-form @submit.prevent="onSubmitLogin">
                <label class="h3 mb-5">Login</label>

                <b-form-group class="mb-2" id="usernameInputGroup" label-for="usernameInput">
                  <b-form-input
                      id="usernameInputGroup"
                      v-model="username"
                      autocomplete="username"
                      required
                      placeholder="Enter username"/>
                </b-form-group>

                <b-form-group
                    id="passwordInputGroup"
                    label-for="passwordInput">
                  <b-form-input
                      id="passwordInputGroup"
                      type="password"
                      autocomplete="current-password"
                      v-model="password"
                      required
                      placeholder="Enter password"/>
                </b-form-group>

                <b-button type="submit" variant="primary" class="mt-5">
                  <b-spinner v-if="loading" type="grow" variant="info" />
                  <p v-else id="submit-text" class="mb-0 p-1">Login</p>
                </b-button>
              </b-form>
            </div>
            <div class="card-footer p-2 text-right">
              <a href="#" @click="forgotPassword=true">Forgot password</a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="forgotPassword" class="row d-flex justify-content-center align-items-center m-0">
        <div class="col-12 col-md-6 col-lg-5 py-5">
          <div class="card text-center bg-light">
            <div class="card-body p-5">
              <b-form @submit.prevent="onSubmitForgotPassword">
                <label class="mb-3 text-left">Please provide your email or username and your login details will be emailed to you.</label>

                <b-form-group class="mb-2" id="usernameInputGroup" label-for="usernameInput">
                  <b-form-input
                      id="usernameInputGroup"
                      v-model="emailOrUsername"
                      required
                      placeholder="Enter email or username"/>
                </b-form-group>

                <b-button type="submit" variant="primary" class="mt-5">
                  <b-spinner v-if="loading" type="grow" variant="info" />
                  <p v-else id="submit-text" class="mb-0 p-1">OK</p>
                </b-button>
              </b-form>
            </div>
            <div class="card-footer p-2 text-right">
              <a href="#" @click="forgotPassword=false">&lt;&lt; Back to login</a>
            </div>
          </div>
        </div>
      </div>

      <about ref="about" />
      <b-button v-if="!isOnlineBuild" id="aboutButton" variant="primary" @click="showAbout">
        <font-awesome-icon size="lg" :icon="['fas','question']"/><br>
      </b-button>
    </div>

</template>


<script>
import About from '../shared/About.vue';
import { description } from '../../../package.json';

export default {
  name: 'login',

  components: {
    About
  },

  data: function () {
    return {
      username: '',
      password: '',
      emailOrUsername: '',
      description,

      loading: false,
      user: false,
      error: '',

      forgotPassword: false,
    }
  },

  mounted() {
    if (this.$route.params.error) {
      this.$swal({ title: '', text: this.$route.params.error, icon: 'error', timer: 10000, button: true });
    }
  },

  computed: {
    isOnlineBuild() { return this.$store.getters["app/isOnline"] },
  },

  methods: {
    async onSubmitLogin() {
      try {
        this.loading = true
        await this.$store.dispatch("user/login", { username: this.username, password: this.password })
        this.$router.replace({ name: 'App:Main' })
      } catch(e) {
        console.log(e);
        if (e.message && e.message === "login/unexpected_error") {
          this.$swal({ title: 'Oops!', text: 'Something went wrong', icon: 'error', timer: 6000, button: true })
        } else if (e.status === 404) {
          this.$swal({ title: 'Could not Log In!', text: 'Wrong Login username and/or pasword.', icon: 'error', timer: 6000, button: true })
        } else {
          this.$swal({ title: 'Oops!', text: 'Something went wrong, check connection', icon: 'error', timer: 6000, error: e, button: true })
        }
      } finally {
        this.loading = false;
      }
    },

    async onSubmitForgotPassword() {
      try {
        this.loading = true
        await this.$store.dispatch('user/forgotPassword', this.emailOrUsername);
      } catch(e) {
        console.log(e);
      } finally {
        this.$swal({
          text: 'If an account exists with the provided email or username, an email will be sent with the login details.',
          icon: 'info', timer: 6000, button: true
        });
        this.forgotPassword = false;
        this.loading = false;
      }
    },

    showAbout() {
      this.$refs.about.show();
    },

  }
}
</script>

<style scoped>
.title {
  color: #1a96cf;
}

#aboutButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: auto;
}
</style>

